<template>
  <div class="dialog">
    <div class="dialog-header">
      <p class="dialog-title text-uppercase mb-0">EDIT USER</p>
    </div>
    <div class="dialog-content" style="overflow-y: auto;">
      <b-container fluid>
        <b-form @submit="onSubmit">
          <b-form-group label="Nickname: "
                        :label-cols="3"
                        label-for="nickname"
                        horizontal>
            <b-form-input id="nickname"
                          type="text"
                          aria-describedby="nickname-input"
                          placeholder="Enter Nickname"
                          autocomplete="off"
                          @input="$v.form.nickname.$touch()"
                          v-model.trim="$v.form.nickname.$model"
                          :state="!$v.form.nickname.$error">
            </b-form-input>
            <b-form-invalid-feedback id="nickname-input">
              Nickname is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="First Name: "
                        :label-cols="3"
                        label-for="firstName"
                        horizontal>
            <b-form-input id="firstName"
                          type="text"
                          aria-describedby="firstName-input"
                          placeholder="Enter First Name"
                          autocomplete="off"
                          @input="$v.form.firstName.$touch()"
                          v-model.trim="$v.form.firstName.$model"
                          :state="!$v.form.firstName.$error">
            </b-form-input>
            <b-form-invalid-feedback id="firstName-input">
              First Name is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Last Name: "
                        :label-cols="3"
                        label-for="lastName"
                        horizontal>
            <b-form-input id="lastName"
                          type="text"
                          aria-describedby="lastName-input"
                          placeholder="Enter Last Name"
                          autocomplete="off"
                          @input="$v.form.lastName.$touch()"
                          v-model.trim="$v.form.lastName.$model"
                          :state="!$v.form.lastName.$error">
            </b-form-input>
            <b-form-invalid-feedback id="lastName-input">
              Last Name is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Email: "
                        :label-cols="3"
                        label-for="email"
                        horizontal>
            <b-form-input id="email"
                          type="email"
                          placeholder="Enter Email"
                          aria-describedby="email-input"
                          autocomplete="nope"
                          v-model.trim="$v.form.email.$model"
                          @input="$v.form.email.$touch()"
                          :state="!$v.form.email.$error">
            </b-form-input>
            <b-form-invalid-feedback id="email-input">
              <span v-if="!$v.form.email.required">Email is required.</span>
              <span v-if="!$v.form.email.email && $v.form.email.required">
                This requires a valid email address.
              </span>
              <span v-if="!$v.form.email.uniqueEmail && $v.form.email.email &&
                      $v.form.email.required">
                Email is already registered.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Role: "
                        :label-cols="3"
                        label-for="role"
                        horizontal>
            <b-form-select id="role"
                           aria-describedby="role-input"
                           @input="$v.form.userType.$touch()"
                           v-model.trim="$v.form.userType.$model"
                           :state="!$v.form.userType.$error"
                           :options="options"
                           :disabled="form.userType === 5">
            </b-form-select>
            <b-form-invalid-feedback id="role-input">
              <span v-if="!$v.form.userType.required">
                Please Select a Role.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Grade Level: "
                        :label-cols="3"
                        label-for="gradeLevel"
                        horizontal
                        v-show="form.userType === 1">
            <b-form-select id="gradeLevel"
                           autocomplete="off"
                           @input="$v.form.gradeLevel.$touch()"
                           v-model.trim="$v.form.gradeLevel.$model"
                           :state="!$v.form.gradeLevel.$error"
                           :options="gradeLevels">
            </b-form-select>
          </b-form-group>
          <b-form-group label="School: "
                        :label-cols="3"
                        label-for="school"
                        v-show="form.userType === 1 || form.userType === 3 ||
                          form.userType === 4"
                        horizontal>
            <school-widget @changed="setSchool"
                           :allowAdd="false"
                           :classes="'edit-user-school'"
                           :initial="initialSchoolValue"
                           ref="schoolWidget"
            />
            <b-form-invalid-feedback id="school">
              <span v-if="!$v.form.schoolId.required">
                School is required.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Status: "
                        label-for="status"
                        :label-cols="3">
            <b-input-group class="mb-0">
              <b-form-input :value="currentStatus"
                            disabled>
              </b-form-input>
              <b-input-group-append>
                <b-button v-if="currentStatus === 'Deactivated' ||
                            currentStatus === 'For Activation'"
                          variant="success"
                          title="Activate Account"
                          @click="updateStatus('activate', currentStatus)">
                  Activate
                </b-button>
                <b-button v-else
                          variant="danger"
                          title="Deactivate Account"
                          @click="updateStatus('deactivate', currentStatus)">
                  Deactivate
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group label="Premium Type: "
                        label-for="premiumType"
                        :label-cols="3"
                        horizontal>
            <toggle-button class="large-font mr-3"
                           id="premiumType"
                           v-model="form.premiumType"
                           :width="100"
                           :height="35"
                           :labels="{
                             checked: 'True',
                             unchecked: 'False'
                           }"/>
          </b-form-group>

          <div class="d-flex justify-content-end">
            <b-button class="text-center"
                      :disabled="$v.form.$invalid || !isFormEdited"
                      type="submit"
                      variant="outline-success">
              Submit
            </b-button>
            <b-button class="text-center ml-1"
                      variant="outline-danger"
                      @click="$emit('close')">
              Cancel
            </b-button>
          </div>
        </b-form>

        <!-- Loading -->
        <loading :active.sync="isLoading"
                 :is-full-page="true"
                 background-color="#000"
                 color="#068bcd"
                 :opacity="0.3"
                 :width="70"
                 :height="70">
        </loading>
      </b-container>
    </div>
  </div>
</template>
<script>
  const Loading = () => import('vue-loading-overlay');
  const SchoolWidget = () => import('@/components/shared/SchoolWidget.vue');

  import 'vue-loading-overlay/dist/vue-loading.css';
  import { required, email } from 'vuelidate/lib/validators';

  export default {
    name  : 'EditUser',
    props : {
      data : Object,
    },
    data() {
      return {
        isLoading    : false,
        isFormEdited : false,
        form         : {
          nickname    : this.data.nickname,
          email       : this.data.email,
          firstName   : this.data.firstname,
          lastName    : this.data.lastname,
          premiumType : Boolean(this.data.premium_type),
          userType    : this.data.user_type,
          schoolId    : (this.data.school_id) ? this.data.school_id : null,
          gradeLevel  : this.data.grade_level,
        },
        user               : {},
        initialSchoolValue : null,
        uniqueEmail        : true,
        options            : [],
        optionsOne         : [ {
          value    : null,
          text     : 'Please select an option',
          disabled : true,
        }, {
          value : 0,
          text  : 'Administrator',
        }, {
          value : 1,
          text  : 'Kid',
        }, {
          value : 2,
          text  : 'Parent',
        }, {
          value : 3,
          text  : 'Educator',
        }, {
          value : 4,
          text  : 'Champion',
        } ],
        optionsTwo : [ {
          value    : null,
          text     : 'Please select an option',
          disabled : true,
        }, {
          value : 4,
          text  : 'Champion',
        }, {
          value : 5,
          text  : 'Influencer',
        } ],
        gradeLevels : [ {
          value    : null,
          text     : 'Grade Level',
          disabled : true,
        }, {
          value : '0',
          text  : 'Kindergarten',
        }, {
          value : '1',
          text  : '1st Grade',
        }, {
          value : '2',
          text  : '2nd Grade',
        }, {
          value : '3',
          text  : '3rd Grade',
        }, {
          value : '4',
          text  : '4th Grade',
        }, {
          value : '5',
          text  : '5th Grade',
        }, {
          value : '6',
          text  : '6th Grade',
        }, {
          value : '7',
          text  : '7th Grade',
        }, {
          value : '8',
          text  : '8th Grade',
        }, {
          value : '9',
          text  : '9th Grade',
        }, {
          value : '10',
          text  : '10th Grade',
        }, {
          value : '11',
          text  : '11th Grade',
        }, {
          value : '12',
          text  : '12th Grade',
        } ],
      };
    },
    computed : {
      formEmail : function () {
        return this.form.email;
      },
      currentStatus : function () {
        let currentStatus = null;

        if (this.user.status === 0)
          currentStatus = 'Deactivated';
        else {
          if (this.user.activation_used === 1)
            currentStatus = 'Activated';
          else
            if (this.user.is_social === 0)
              currentStatus = 'For Activation';
            else
              currentStatus = 'Activated';
        }

        return currentStatus;
      },
      formUserType : function () {
        return this.form.userType;
      },
    },
    components : {
      Loading,
      SchoolWidget,
    },
    watch : {
      formEmail : function () {
        this.uniqueEmail = true;
        this.checkEmail();
      },
      formUserType : function () {
        this.form.gradeLevel = null;
        this.$v.form.gradeLevel.$reset();
        this.$refs.schoolWidget.resetSelectedSchool();
      },
    },
    methods : {

      /**
       * Check if Email is already registered
       */
      // eslint-disable-next-line no-undef
      checkEmail : _.debounce(function () {
        this.$http.get('api/user/email', {
          params : {
            email : this.form.email,
          },
        }).then(response => {
          // swapped value since api returns true
          //   if email exists hence email is not unique
          this.uniqueEmail = (response.data) ? false : true;

          // Check if entered email is the same as old email
          if (this.data.email === this.form.email)
            this.uniqueEmail = true;
        });
      }, 500),


      /**
       * Check if Form is Edited
       * @param newValue
       */
      formUpdated(newValue) {
        this.isFormEdited = false;

        if (newValue.nickname !== this.data.nickname ||
          newValue.firstName !== this.data.firstname ||
          newValue.lastName !== this.data.lastname ||
          newValue.userType !== this.data.user_type ||
          newValue.schoolId !== this.data.school_id ||
          newValue.gradeLevel !== this.data.grade_level ||
          newValue.premiumType !== Boolean(this.data.premium_type))
          this.isFormEdited = true;
      },

      /**
       * Edit User
       */
      onSubmit(e) {
        e.preventDefault();
        this.isLoading = true;
        this.form.premiumType = (this.form.premiumType) ? 1 : 0;

        this.$http.put('api/user', {
          'userId'       : this.data.user_id,
          'updateFields' : {
            'nickname'     : this.form.nickname,
            'email'        : this.form.email,
            'firstname'    : this.form.firstName,
            'lastname'     : this.form.lastName,
            'premium_type' : this.form.premiumType,
            'user_type'    : this.form.userType,
            'school_id'    : this.form.schoolId,
            'grade_level'  : this.form.gradeLevel,
          },
        }).then(() => {
          this.isLoading = false;
          this.$parent.$emit('update-user-list');
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Updated user ' + this.form.firstName + ' ' +
              this.form.lastName + ' successfully!',
          });
          this.closeModal();
        }).catch((err) => {
          this.isLoading = false;
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : err.response.data.msg,
          });
          this.closeModal();
        });
      },

      /**
       * Close 'Edit User' modal
       */
      closeModal() {
        this.$emit('modalClose');
        this.$emit('close');
      },

      /**
       * Set School for manual input
       * @param data
       */
      setSchool(data) {
        this.form.schoolId = data;
      },

      /**
       * Update User's Status or Activation only
       * @param action
       * @param currentStatus
       */
      updateStatus(action, currentStatus) {
        this.isLoading = true;
        let updateFields = {};

        if (action === 'activate') {
          if (currentStatus === 'For Activation')
            updateFields = { 'activation_used' : 1 };

          if (currentStatus === 'Deactivated')
            updateFields = { 'status' : 1 };
        } else {
          if (currentStatus === 'Activated')
            updateFields = { 'status' : 0 };
        }
        
        this.$http.put('api/user', {
          'userId'       : this.data.user_id,
          'updateFields' : updateFields,
        }).then(() => {
          this.getUserData(this.data.user_id);
          this.$parent.$emit('update-user-list');
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : (action === 'activate') ?
              'Activated account successfully' :
              'Deactivated account successfully',
          });
        }).catch((err) => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : err.response.data.msg,
          });
        }).finally(() => {
          this.isLoading = false;
        });
      },
      
      /**
       * Get User Data
       * @param userID
       */
      getUserData(userID) {
        this.$http.get('api/user', {
          params : {
            id : userID,
          },
        }).then(response => {
          this.user = response.data;
        });
      },
    },
    created() {
      this.getUserData(this.data.user_id);

      if (this.data.school_id)
        this.initialSchoolValue = this.data.school_id;

      this.$watch('form', this.formUpdated, {
        deep : true,
      });
    },
    mounted() {
      // Assign User Type Options
      switch (this.data.user_type) {
      case 5:
        this.options = this.optionsTwo;
        break;
      default:
        this.options = this.optionsOne;
        break;
      }
    },
    validations() {
      return {
        form : {
          nickname  : { required },
          firstName : { required },
          lastName  : { required },
          userType  : { required },
          schoolId  : (this.form.userType === 1 || this.form.userType === 3 ||
            this.form.userType === 4) ? { required } : {},
          gradeLevel  : (this.form.userType === 1) ? { required } : {},
          premiumType : {},
          email       : {
            email,
            required,
            uniqueEmail() {
              return this.uniqueEmail;
            },
          },
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/userlist/edit-user";
</style>
